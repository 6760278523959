<template>
  <div class="view-watch">
    <action-dispatcher action="contents/get" :parameters="{ id }" loading-class="p-5 m-5">
      <div class="d-none d-md-block">
        <video-player
          :id="id"
          :season="season"
          :duration="videoDuration"
          :poster="image"
          :src="videoSrc"
          :subtitles="subtitles"
          :start-time="startTime"
          @controlshidden="showTitle = false"
          @controlsshown="showTitle = true"
        />
      </div>

      <div class="d-md-none mobile" :style="`background-image: url(${image})`">
        <div class="container">
          <!-- <h3 class="text-light my-5">{{ content.title }}</h3> -->
          <div class="flex-fill"></div>
          <div class="my-5">
            <h3 class="text-light mb-4">Assista à OCE Play no seu smartphone ou tablet</h3>
            <a v-if="isApple" href="https://apps.apple.com/br/app/o-corpo-explica-play/id1513040694" class="btn btn-block btn-lg btn-light">
              <img src="../assets/images/app-store.png" />
              Baixe o aplicativo
            </a>
            <a v-else href="https://play.google.com/store/apps/details?id=com.ocorpoexplica.mensageiro" class="btn btn-block btn-lg btn-light">
              <img src="../assets/images/google-play.png" />
              Baixe o aplicativo
            </a>
          </div>
        </div>
      </div>

      <div class="top-wrapper d-flex align-items-center" :class="{ hide: !showTitle }">
        <button class="btn-back d-flex" @click="back">
          <i class="icon-left"></i>
        </button>
        {{ content.title }}
      </div>
    </action-dispatcher>
  </div>
</template>

<script>
import VideoPlayer from '../components/watch/VideoPlayer'
import { mapState } from 'vuex'
import get from 'lodash/get'
import isMobile from 'ismobilejs'

export default {
  components: { VideoPlayer },
  data () {
    return {
      id: this.$route.params.id,
      showTitle: true,
      isApple: isMobile(window.navigator).apple.device
    }
  },
  computed: {
    ...mapState('seasons', ['seasons']),
    ...mapState('contents', ['content']),
    image () {
      return get(get(this.content, 'thumbnails', []).find(item => item.device === 'web'), 'file', '')
    },
    videoSrc () {
      return get(this.content, 'video.url_m3u8', null)
    },
    subtitles () {
      return get(this.content, 'subtitles', [])
    },
    videoDuration () {
      return get(this.content, 'video.duration', null)
    },
    startTime () {
      return get(this.content, 'continue_watch.duration', 0)
    },
    season () {
      return this.seasons.find(season => season.id === get(this.content, 'season_tmp.id')) || {}
    }
  },
  methods: {
    back () {
      this.$router.push({
        name: 'content',
        params: {
          id: this.content.id,
          slug: this.content.slug
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.view-watch {
  ::v-deep .top-wrapper {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    text-shadow: 0 0 5px black;
    color: white;
    transition: 0.3s opacity;

    .btn-back {
      color: white;
      font-size: 48px;
      padding: 0;
      background: transparent;
      border: none;
    }

    &.hide {
      opacity: 0;
    }

    &:hover {
      opacity: 1;
    }
  }

  .mobile {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;

    a {
      img {
        max-height: 32px;
      }
    }

    h3 {
      font-weight: 900;
      text-align: center;
      text-shadow: 0 0 5px black;
    }

    .container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image:
        linear-gradient(to top, rgba(0, 2, 21, 0.8), rgba(0, 2, 21, 0.3) 50%),
        linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3));
    }
  }
}
</style>
