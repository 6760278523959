<template>
  <div
    class="watch-speed popover show bs-popover-top p-2"
    role="tooltip"
    id="popover693646"
    x-placement="top"
    v-click-outside="close"
  >
    <div class="arrow"></div>
    <div class="popover-body text-light">
      <button type="button" class="btn-close text-white" @click="close">
        <i class="icon-close"></i>
      </button>
      <h6 class="font-weight-normal">Qualidade</h6>

      <div class="buttons">
        <template v-for="q in qualities">
          <button
            v-if="q !== undefined"
            :key="q"
            class="btn btn-sm text-light"
            :class="{ 'font-weight-bold': q === quality }"
            @click="$emit('update-quality', q)"
          >
            {{ qualityName(q) }}
          </button>
        </template>
      </div>

      <h6 class="font-weight-normal mt-4">Velocidade</h6>

      <div class="buttons">
        <button
          v-for="speed in speeds"
          :key="speed"
          class="btn btn-sm text-light"
          :class="{ 'font-weight-bold': speed === value }"
          @click="$emit('input', speed)"
        >
          {{ speed }}x
        </button>
      </div>

      <h6 class="font-weight-normal mt-4">Legenda</h6>

      <div class="buttons">
        <button
          class="btn btn-sm text-light"
          :class="{ 'font-weight-bold': -1 === subtitle }"
          @click="$emit('update-subtitle', -1)"
        >
          Nenhum
        </button>

        <template v-for="(s, index) in subtitles">
          <button
            v-if="s !== undefined"
            :key="s.language"
            class="btn btn-sm text-light"
            :class="{ 'font-weight-bold': index === subtitle }"
            @click="$emit('update-subtitle', index)"
          >
            {{ getCodeName(s.language) }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside
  },
  props: {
    value: {
      type: Number,
      default: 1
    },
    quality: {
      type: [Number, String],
      default: 0
    },
    qualities: {
      type: Array,
      default: () => [0]
    },
    subtitle: {
      type: Number,
      default: -1
    },
    subtitles: {
      type: Array,
      required: () => []
    }
  },
  data () {
    return {
      commentId: null,
      editing: false,
      text: '',
      loadingSave: false,
      showingForm: false,
      speeds: [0.5, 1, 1.25, 1.5, 1.75, 2]
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    qualityName (q) {
      if (q === 0) {
        return 'Automático'
      } else {
        return `${q}p`
      }
    },
    getCodeName (val) {
      switch (val) {
        case 'pt-BR':
          return 'Português'
        case 'por':
          return 'Português'
        case 'pt':
          return 'Português Pt'
        case 'en':
          return 'Inglês'
        case 'eng':
          return 'Inglês'
        case 'es':
          return 'Espanhol'
        case 'fr':
          return 'Francês'
        case 'fra':
          return 'Francês'
        case 'de':
          return 'Alemão'
        case 'it':
          return 'Italiano'
        case 'ko':
          return 'Koreano'
        case 'ro':
          return 'Russo'
        case 'ja':
          return 'Japonês'
        case 'zho':
          return 'Chinês'
        case 'pol':
          return 'Polonês'
        default:
          return val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.watch-speed {
  top: auto;
  left: auto;
  right: 10px;
  bottom: 42px;
  min-width: 300px;
  background: #212631;

  .arrow {
    right: 36px;

    &::after {
      border-top-color: #212631 !important
    }
  }

  .btn-close {
    position: absolute;
    top: 8px;
    right: 8px;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 28px;
    height: 28px;
    line-height: 28px;
  }

  .buttons {
    margin: 0 -8px;
  }
}
</style>
