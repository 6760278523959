<template>
  <div
    class="watch-comments popover show bs-popover-top p-2"
    role="tooltip"
    id="popover693646"
    x-placement="top"
    v-click-outside="close"
  >
    <div class="arrow"></div>
    <div class="popover-body text-light">
      <button type="button" class="btn-close text-white" @click="close">
        <i class="icon-close"></i>
      </button>
      <h6 class="font-weight-normal">Minhas anotações</h6>

      <div v-if="loading || loadingSave" class="d-flex justify-content-center align-items-center p-4">
        <loading-spinner />
      </div>

      <form v-else-if="showingForm" @submit.prevent="save">
        <textarea
          v-model="text"
          class="border-0 bg-transparent form-control p-0 text-light"
          placeholder="Adicione a sua anotação..."
          rows="3"
          required
        ></textarea>
        <button type="submit" class="btn btn-block btn-secondary mt-2" @click="showForm">
          Salvar
        </button>
      </form>

      <template v-else>
        <div v-if="comments.length === 0" class="text-center text-secondary py-3">Nenhuma anotação</div>
        <div class="comments-wrap">
          <div v-for="(comment, index) in comments" class="py-3 d-flex" :key="index">
            <span>{{ comment.text }}</span>
            <div class="flex-fill"></div>
            <div class="d-flex flex-column pl-2 text-right">
              <small class="text-secondary">{{ getMoment(comment.interval) }}</small>
              <div class="d-flex">
                <button type="button" class="btn p-0 comment-btn" @click="() => edit(comment)">
                  <i class="icon-pencil text-secondary"></i>
                </button>
                <button type="button" class="btn p-0 comment-btn" @click="() => remove(comment)">
                  <i class="icon-trash text-secondary"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-block btn-secondary mt-2" @click="showForm">
          <i class="icon-comments"></i> Nova anotação
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { getMoment } from '@/functions/helpers'

export default {
  directives: {
    ClickOutside
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    comments: {
      type: Array,
      default: () => []
    },
    currentTime: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      commentId: null,
      editing: false,
      text: '',
      loadingSave: false,
      showingForm: false
    }
  },
  methods: {
    getMoment,
    save () {
      const action = this.editing ? 'comments/update' : 'comments/create'
      this.loadingSave = true
      this.$store
        .dispatch(action, {
          id: this.commentId,
          contentId: this.id,
          text: this.text,
          interval: Math.floor(this.currentTime)
        })
        .then(() => {
          this.text = ''
          this.showingForm = false
          this.$emit('saved')
        })
        .finally(() => {
          this.loadingSave = false
          this.editing = false
        })
    },
    edit (comment) {
      this.editing = true
      this.commentId = comment.id
      this.text = comment.text
      this.showForm()
    },
    remove (comment) {
      setTimeout(() => {
        this.loadingSave = true
      }, 1)
      this.$store
        .dispatch('comments/remove', comment.id)
        .then(() => {
          this.$emit('saved')
        })
        .finally(() => {
          this.loadingSave = false
        })
    },
    close () {
      this.$emit('close')
    },
    showForm () {
      setTimeout(() => {
        this.showingForm = true
      }, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.watch-comments {
  top: auto;
  left: auto;
  right: 10px;
  bottom: 42px;
  min-width: 300px;
  background: #212631;

  .arrow {
    right: 69px;

    &::after {
      border-top-color: #212631 !important
    }
  }

  .btn-close {
    position: absolute;
    top: 8px;
    right: 8px;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 28px;
    height: 28px;
    line-height: 28px;
  }

  .comments-wrap {
    max-height: 200px;
    overflow-y: scroll;
    margin: 0 -20px;
    padding: 0 20px;
  }

  .comment-btn {
    font-size: 14px;
  }
}
</style>
