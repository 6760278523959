<template>
  <div
    class="watch-episodes popover show bs-popover-top p-2"
    role="tooltip"
    id="popover693646"
    x-placement="top"
    v-click-outside="close"
  >
    <div class="arrow"></div>
    <div class="popover-body text-light">
      <button type="button" class="btn-close text-white" @click="close">
        <i class="icon-close"></i>
      </button>
      <h6 class="font-weight-normal">Todos os episódios ({{ episodes.length }})</h6>

      <div v-if="loading" class="d-flex justify-content-center align-items-center p-4">
        <loading-spinner />
      </div>

      <template v-else>
        <div v-if="episodes.length === 0" class="text-center text-secondary py-3">Nenhum episódio</div>
        <div class="episodes-wrap">
          <button
            v-for="(episode, index) in episodes"
            class="py-3 d-flex align-items-center w-100"
            :key="index"
            @click="goTo(episode)"
          >
            <img
              v-if="episode.thumbnails && episode.thumbnails.length"
              :src="image(episode)"
            />
            <div class="d-flex flex-column pl-2">
              <small class="text-secondary">Temporada {{ episode.season.number }} Episódio {{ episode.episode }}</small>
              <span>{{ episode.title }}</span>
              <div class="flex-fill"></div>
            </div>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import get from 'lodash/get'

export default {
  directives: {
    ClickOutside
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    episodes: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    image (item) {
      return get(get(item, 'thumbnails', []).find(item => item.device === 'web'), 'small', '')
    },
    close () {
      this.$emit('close')
    },
    goTo (episode) {
      this.$router.push({
        name: 'watch',
        params: {
          id: episode.id,
          slug: episode.slug
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.watch-episodes {
  top: auto;
  left: auto;
  right: 10px;
  bottom: 42px;
  min-width: 320px;
  background: #212631;

  .arrow {
    right: 102px;

    &::after {
      border-top-color: #212631 !important
    }
  }

  .btn-close {
    position: absolute;
    top: 8px;
    right: 8px;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 28px;
    height: 28px;
    line-height: 28px;
  }

  .episodes-wrap {
    max-height: 220px;
    overflow-y: scroll;
    margin: 0 -20px;

    button {
      background: transparent;
      border: none;
      padding: 0 20px;
      text-align: left;
      color: white;
      transition: all .2s;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    img {
      max-width: 60px;
    }
  }
}
</style>
